/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Roboto:wght@400;500&display=swap');

/* Color palette */
:root {
  --dark-teal: #00575f;
  --teal: #83c5be;
  --light-mint: #edf6f9;
  --yellow: #fca311;
  --white: #ffffff;
  --light-blue: #e6f3f5;
  --dark-blue: #004052;
  --gray-700: #4a5568;
  --air-force-blue: #698996;
}

/* Global styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--white);
  color: var(--dark-teal);
  line-height: 1.6;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  color: var(--dark-teal);
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

p {
  font-size: 1rem;
  color: var(--gray-700);
}

/* Buttons */
.btn {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: var(--yellow);
  color: var(--dark-teal);
  border: none;
}

.btn-primary:hover {
  background-color: #e69500;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Header */
header {
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

header a {
  color: var(--dark-teal);
  text-decoration: none;
  transition: color 0.3s ease;
}

header a:hover {
  color: var(--teal);
}

/* Logo */
.logo-icon {
  background-color: var(--dark-teal);
  color: var(--white);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.logo-text {
  color: var(--dark-teal);
  font-weight: bold;
  font-size: 1.5rem;
}

/* Footer */
footer {
  background-color: var(--dark-teal);
  color: var(--white);
}

footer p,
footer a,
footer .copyright {
  color: var(--white);
}

footer a {
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: var(--yellow);
}

footer h3 {
  color: var(--white);
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

footer ul {
  list-style-type: none;
  padding: 0;
}

footer ul li {
  margin-bottom: 0.5rem;
}

footer .social-icons {
  display: flex;
  gap: 1rem;
}

footer .social-icons a {
  font-size: 1.5rem;
}

footer .contact-info p {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

footer .contact-info svg {
  margin-right: 0.5rem;
}

footer .copyright {
  font-size: 0.875rem;
  opacity: 0.8;
}

/* FAQ Section Styles */
.faq-section {
  background-color: #f4f7fb;
  padding: 2rem 0;
}

.faq-section h1 {
  color: #065f46;
  font-weight: bold;
}

.faq-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  border: 1px solid #cfd8dc; /* Soft blue border */
}

.faq-item:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px); /* Slight lift effect */
}

.faq-question {
  font-size: 1.5rem;
  color: #065f46;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-answer {
  color: #4a4a4a;
  line-height: 1.6;
  overflow: hidden;
  padding: 0.5rem 0;
  animation: fade-in 0.3s ease-out;
}

/* Smooth fade-in effect for FAQ answer */
@keyframes fade-in {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.faq-icon {
  font-weight: bold;
  font-size: 1.75rem;
  color: #ffc107; /* Soft yellow for the icon */
}

.faq-section .container {
  max-width: 800px;
  margin: auto;
  padding: 0 1.5rem;
}

/* Custom styles */
.bg-accent {
  background-color: var(--yellow);
}

.text-accent {
  color: var(--yellow);
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-dark-blue {
  background-color: var(--dark-blue);
}

.bg-air-force-blue {
  background-color: var(--air-force-blue);
}

/* Hero section */
.hero-section {
  background-color: var(--dark-teal);
  padding: 4rem 0;
}

.hero-section h1 {
  color: var(--white);
  margin-bottom: 1rem;
}

.hero-section p {
  color: var(--white);
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

/* Careers Section */
.careers-section {
  background-color: #f4f7fb; /* Light blue background */
  padding: 4rem 0;
}

.careers-section h1, .careers-section h2, .careers-section h3, .careers-section h4 {
  color: #065f46; /* Dark teal */
  font-weight: bold;
}

.careers-section p, .careers-section li {
  color: #4a4a4a; /* Neutral dark gray */
  line-height: 1.7;
}

.careers-section ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

.careers-section a {
  color: #065f46;
  font-weight: bold;
  text-decoration: underline;
}

/* Terms of Service Section */
.terms-of-service {
  background-color: #f4f7fb; /* Light blue background */
  padding: 4rem 0;
}

.terms-of-service h1 {
  color: #065f46; /* Dark teal */
  font-weight: bold;
  margin-bottom: 2rem;
}

.terms-of-service h2 {
  color: #065f46; /* Dark teal */
  font-size: 1.75rem;
  font-weight: bold;
  margin-top: 2rem;
}

.terms-of-service p {
  color: #4a4a4a; /* Neutral dark gray for readability */
  line-height: 1.7;
}

.terms-of-service ul {
  margin-left: 1.25rem;
  list-style-type: disc;
  color: #4a4a4a;
}

.terms-of-service a {
  color: #065f46;
  font-weight: bold;
  text-decoration: underline;
}

.terms-of-service .container {
  max-width: 900px;
  margin: auto;
}

/* Privacy Policy Section */
.privacy-policy {
  background-color: #f4f7fb; /* Light blue background */
  padding: 4rem 0;
}

.privacy-policy h1 {
  color: #065f46; /* Dark teal */
  font-weight: bold;
  margin-bottom: 2rem;
}

.privacy-policy h2 {
  color: #065f46; /* Dark teal */
  font-size: 1.75rem;
  font-weight: bold;
  margin-top: 2rem;
}

.privacy-policy p {
  color: #4a4a4a; /* Neutral dark gray for readability */
  line-height: 1.7;
}

.privacy-policy ul {
  margin-left: 1.25rem;
  list-style-type: disc;
  color: #4a4a4a;
}

.privacy-policy a {
  color: #065f46;
  font-weight: bold;
  text-decoration: underline;
}

.privacy-policy .container {
  max-width: 900px;
  margin: auto;
}



/* Why Choose Credit Chakra section */
.hover-card {
  transition: all 0.3s ease;
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

/* Bank logo animation */
.bank-logo-container {
  width: 100%;
  overflow: hidden;
}

.bank-logo-scroll {
  display: flex;
  animation: scroll 40s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.bank-logo {
  flex: 0 0 auto;
  transition: all 0.3s ease;
}

.bank-logo:hover {
  transform: scale(1.05);
}

/* We Understand Your Financial Challenges section */
.bg-dark-teal {
  background-color: var(--dark-teal);
}

.text-white {
  color: var(--white);
}

.bg-dark-teal h2 {
  color: var(--white);
}

/* Mobile CTA button styles */
.mobile-cta {
  display: inline-block;
  width: 100%;
  max-width: 300px;
}

.mobile-cta .desktop-text {
  display: inline;
}

.mobile-cta .mobile-text {
  display: none;
}

/* Your Path to Seamless Financial Support section */
.path-step {
  transition: all 0.3s ease;
}

.path-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

/* Responsive design */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  .hero-section {
    padding: 2rem 0;
  }

  .bank-logo-scroll {
    animation: scroll 30s linear infinite;
  }

  .bank-logo img {
    width: 64px;
    height: 64px;
  }

  .bank-logo {
    margin: 0 12px;
  }

  /* Improve mobile CTA button styles */
  .mobile-cta {
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
  }

  .mobile-cta .desktop-text {
    display: none;
  }

  .mobile-cta .mobile-text {
    display: inline;
  }

  /* Improve mobile layout */
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid {
    gap: 1rem;
  }

  .path-step {
    margin-bottom: 1rem;
  }

  /* Adjust layout for smaller screens */
  .flex-col-mobile {
    flex-direction: column;
  }

  .w-full-mobile {
    width: 100%;
  }

  .mb-4-mobile {
    margin-bottom: 1rem;
  }

  .text-center-mobile {
    text-align: center;
  }

  /* Footer responsive styles */
  footer .grid {
    grid-template-columns: 1fr;
  }

  footer .social-icons {
    justify-content: center;
    margin-top: 1rem;
  }

  footer .contact-info {
    text-align: center;
  }

  footer .contact-info p {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .bank-logo-scroll {
    animation: scroll 20s linear infinite;
  }

  .bank-logo img {
    width: 48px;
    height: 48px;
  }

  .bank-logo {
    margin: 0 8px;
  }

  /* Further adjustments for smaller screens */
  .mobile-cta {
    padding: 10px 16px;
    font-size: 14px;
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  .hero-section {
    padding: 1.5rem 0;
  }

  /* Adjust spacing for mobile */
  .py-16 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .mb-12 {
    margin-bottom: 1.5rem;
  }

  .gap-8 {
    gap: 1rem;
  }
}

/* Additional improvements */
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.transition {
  transition: all 0.3s ease;
}

/* Improve accessibility */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Add smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Improve form styles */
input, select, textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid var(--gray-700);
  border-radius: 0.25rem;
}

input:focus, select:focus, textarea:focus {
  outline: none;
  border-color: var(--teal);
  box-shadow: 0 0 0 2px rgba(131, 197, 190, 0.2);
}

/* Add a back-to-top button */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--yellow);
  color: var(--dark-teal);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.back-to-top.show {
  opacity: 1;
}

.back-to-top:hover {
  background-color: #e69500;
}

/* Change background color for "Your Path to Seamless Financial Support" section */
.bg-light-blue {
  background-color: var(--light-blue);
}